import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import 'swagger-ui-react/swagger-ui.css';
import Layout from '../../components/layout';

const API_SPEC_URL = {
  V1: process.env.GATSBY_API_V1_URL,
  V2: process.env.GATSBY_API_V2_URL,
};

class LazySwagger extends React.Component {
  constructor(props) {
    super(props);
    this.module = null;
    this.apiSpecUrl = props.apiSpecUrl;

    this.state = {
      loaded: false,
    };
  }

  // after the initial render, wait for module to load
  async componentDidMount() {
    const { resolve } = this.props;
    const resolved = await resolve();
    this.module = resolved.default;
    this.setState({ loaded: true });
  }

  render() {
    const { loaded } = this.state;

    if (!loaded) return <div>Loading module...</div>;
    return React.createElement(this.module, { url: this.apiSpecUrl, deepLinking: true });
  }
}

LazySwagger.propTypes = {
  resolve: PropTypes.func.isRequired,
  apiSpecUrl: PropTypes.string,
};

export const Swagger = ({ location, params }) => {
  const apiSpecUrl = useMemo(() => {
    return params.version ? API_SPEC_URL[params.version.toUpperCase()] : null;
  }, [params]);

  return (
    <Layout location={location}>
      <LazySwagger apiSpecUrl={apiSpecUrl} resolve={() => import('swagger-ui-react')} />
    </Layout>
  );
};

Swagger.propTypes = {
  location: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({
    version: PropTypes.string,
  }),
};

export default Swagger;
